// @ts-nocheck

import React, { ReactNode } from "react";

/* drawings */
import { DrawingNoah } from "app/drawings/Noah";
import { DrawingFox } from "app/drawings/Fox";
import { DrawingAngryFurniture } from "app/drawings/AngryFurniture";
import { DrawingLonelyPlanet } from "app/drawings/LonelyPlanet";
import { DrawingLava } from "app/drawings/Lava";
import { DrawingSorcer } from "app/drawings/Sorceresses";
import { DrawingBeneath } from "app/drawings/Beneat";
import { DrawingDarkLand } from "app/drawings/TheDarkLand";
import { DrawingCanoes } from "app/drawings/Canoes";
import { DrawingCharlieFinn } from "app/drawings/CharlieFinn";

/* audio */

import AudioAngryFurniture from "app/assets/audio/angry_furniture.mp3";
import AudioBeneath from "app/assets/audio/beneath_the_dark.mp3";
import AudioCanoes from "app/assets/audio/canoes.mp3";
import AudioCharlieFinn from "app/assets/audio/charlie_finn.mp3";
import AudioDarkLand from "app/assets/audio/dark_land_of_the_underground.mp3";
import AudioExtinctionDay from "app/assets/audio/extinction_day.mp3";
import AudioFoxAndIphone from "app/assets/audio/fox_and_iphone.mp3";
import AudioLava from "app/assets/audio/lava_sesemi.mp3";
import AudioNoah from "app/assets/audio/noah.mp3";
import AudioSorcer from "app/assets/audio/sorcerer.mp3";

/* stories */

import { StoryFoxIphone4 } from "app/data/stories/story-fox-iphone";
import { StoryBeneathTheDark } from "app/data/stories/beneath-the-dark";
import { StoryAbstract } from "app/data/stories/story-abstract";
import { StoryNoah } from "app/data/stories/story-noah";
import { StoryAngryFurniture } from "app/data/stories/story-angry-furniture";
import { StoryLonelyPlanetorthedeathoftheEgo } from "app/data/stories/lonely-planet-or-the-death-of-the-ego";

import { StoryTheSorceresses } from "app/data/stories/the-sorceresses";

import { StoryCharlieFinn } from "app/data/stories/charlie-finn";
import { StoryThedarklandoftheUnderground } from "app/data/stories/the-dark-land-of-the-undergrounds";
import { StoryLava } from "app/data/stories/lava";
import { StoryCanoes } from "app/data/stories/canoes";

export interface ItemProps {
  path?: string;
  audioPath?: string;
  textPath?: string;
  itemName?: string;
  drawing?: ReactNode;
  text?: string;
}

export const itemList: ItemProps[] = [
  {
    path: "/abstract",
    itemName: "Abstract",
    audioPath: undefined,
    textPath: "",
    text: <StoryAbstract />,
    drawing: <DrawingFox />,
  },
  {
    path: "/noah",
    itemName: "Noah",
    audioPath: AudioNoah,
    textPath: "",
    text: <StoryNoah />,
    drawing: <DrawingNoah />,
  },
  {
    path: "/the-fox-and-the-iphone-4",
    itemName: "The Fox and The Iphone 4",
    audioPath: AudioFoxAndIphone,
    textPath: "",
    text: <StoryFoxIphone4 />,
    drawing: (
      <DrawingFox
        css={`
          position: absolute;
          top: 0;
        `}
      />
    ),
  },
  {
    path: "/angry-furniture",
    itemName: "Angry Furniture",
    audioPath: AudioAngryFurniture,
    textPath: "",
    text: <StoryAngryFurniture />,
    drawing: <DrawingAngryFurniture />,
  },
  {
    path: "/lonely-planet-or-the-death-of-the-ego",
    itemName: "Lonely Planet or the Death of the ego",
    audioPath: AudioExtinctionDay,
    textPath: "",
    text: <StoryLonelyPlanetorthedeathoftheEgo />,
    drawing: <DrawingLonelyPlanet />,
  },

  {
    path: "/lava",
    itemName: "LAVA",
    audioPath: AudioLava,
    textPath: "",
    text: <StoryLava />,
    drawing: <DrawingLava />,
  },
  {
    path: "/the-sorceresses",
    itemName: "The Sorceresses",
    audioPath: AudioSorcer,
    textPath: "",
    text: <StoryTheSorceresses />,
    drawing: <DrawingSorcer />,
  },
  {
    path: "/beneath-the-dark",
    itemName: "Beneath the Dark",
    audioPath: AudioBeneath,
    textPath: "",
    text: <StoryBeneathTheDark />,
    drawing: <DrawingBeneath />,
  },
  {
    path: "/the-dark-land-of-the-undergrounds",
    itemName: "The Dark Land of the Undergrounds",
    audioPath: AudioDarkLand,
    textPath: "",
    text: <StoryThedarklandoftheUnderground />,
    drawing: <DrawingDarkLand />,
  },
  {
    path: "/canoes",
    itemName: "Canoes",
    audioPath: AudioCanoes,
    textPath: "",
    text: <StoryCanoes />,
    drawing: <DrawingCanoes />,
  },
  {
    path: "/charlie-finn",
    itemName: "Charlie Finn",
    audioPath: AudioCharlieFinn,
    textPath: "",
    text: <StoryCharlieFinn />,
    drawing: <DrawingCharlieFinn />,
  },
];
