import React from "react";
import { Theme } from "app/theme";
/* ------------------------------------------------------------- */
interface LinkItem {
  children?: React.ReactNode;
}
const ItemLink = (props: LinkItem) => (
  <div
    css={`
      color: ${Theme.textStyles.landingItemLink.color};
      font-style: ${Theme.textStyles.landingItemLink.fontStyle};
      font-weight: ${Theme.textStyles.landingItemLink.fontWeight};
      font-size: ${Theme.textStyles.landingItemLink.fontSize};
      text-shadow: ${Theme.textEffects[0]};
      cursor: pointer;
      user-select: none;

      &:hover {
        opacity: 0.5;
      }
    `}
  >
    {props.children}
  </div>
);
/* ------------------------------------------------------------- */
interface LinkItemContainerProps {
  children?: React.ReactNode;
}
const LinkItemContainer = (props: LinkItemContainerProps) => (
  <div
    css={`
      display: flex;
      margin-right: 20px;
      align-items: flex-end;
      line-height: 1;
    `}
  >
    {props.children}
  </div>
);
/* ------------------------------------------------------------- */
const LinkSeparator = () => (
  <div
    css={`
      margin-left: 5px;
      margin-right: 5px;
      user-select: none;
      color: ${Theme.textStyles.landingItemLink.color};
    `}
  >
    /
  </div>
);
/* ------------------------------------------------------------- */
interface LinkItemNameProps {
  children?: React.ReactNode;
}
export const ItemName = (props: LinkItemNameProps) => (
  <div
    css={`
      color: ${Theme.textStyles.landingItemLink.color};
      font-family: ${Theme.textStyles.landingItemLink.fontFamily};
      font-style: italic;
      font-weight: normal;
      font-size: 24px;
      text-shadow: ${Theme.textEffects[0]};
      line-height: 0.9;
      cursor: pointer;
      user-select: none;
      transition: all 0.2s ease;

      @media (max-width: 960px) {
        font-size: 18px;
        /* font-weight: medium; */
      }

      &:hover {
        //opacity: 0.5;
        text-shadow: ${Theme.textEffects[1]};
        transform: translateX(10px);
      }
    `}
  >
    {props.children}
  </div>
);
