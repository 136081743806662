import React from "react";

export const StoryLonelyPlanetorthedeathoftheEgo = () => (
  <React.Fragment>
    {/* ---------------------------------------------------------- */}
    {/*<h3>Lonely Planet or the death of the Ego</h3>*/}
    {/* we use the <p> tag for paragraphs */}
    <p>
      &emsp;Very few survived the death of the Sun. When that day arrived, all
      specialists had already warned humanity years in advance. Collective
      suicides, extra-terrestrial expeditions, serial travellers and other
      alienating tendencies emerged, which resulted in a primary form of purge.
      The Earth had a third of its population left when Extinction Day arrived,
      and so it happened without too much hysteria. The last 14 minutes of
      sunlight were spent in silence. Then the extreme cold arrived, most people
      left themselves die of hypothermia, a few others overdosed on sleeping
      pills. In Earth’s final years it became increasingly trendy to abuse
      prescription drugs in order to stop feeling too much. Psychologists
      stopped practicing; medicine rapidly decayed and only minor illnesses were
      treated. This nihilist humanity was just waiting for death. Humankind
      became cold long before it died from it.
      <br />
      &emsp;But, when that day came, a group of monks and nuns were ready.
      Ready, with bunkers stacked with power sources to last for a hundred
      years, knowledge to restart a civilisation, enough food, water tanks and a
      few board games. They made sure to have all they needed to find a way,
      slowly but surely, to live underground and eventually train their skin for
      the cold. They brought everything but their egos, those froze to death
      with the rest. There was only one major complication: reproduction. Whilst
      their knowledge was vast, the wise-ones knew little to nothing on the art
      of sex. During the time when Earth was waiting for its end, natality
      decreased dramatically. The only way to reproduce, you see, was to have
      pleasurable intercourse. This opened the gates to another sexual
      revolution, this time focused on satisfactory intercourse for
      reproduction. Women's bodies were praised and venerated, and within this
      apocalyptical scenario a spark of positivity happened: gender equality was
      finally established. The very few crazy people that still decided to have
      children despite the end of the world approaching, did it exclusively out
      of bliss. Kids were a product of pleasure.
      <br />
      &emsp;Amongst the enciclopedias the wise-ones collected, was the Kama
      Sutra. The Indians were great in depicting the technicalities necessary
      for the act, as well as the art of loving and its poetics. But the
      practice is always more abstract than the theory. The logistics behind
      intercourse were somewhat clear, but to read about the impalpable magic of
      love-making confused the survivors. The sentiments so vital for life to
      come together were a world apart for those so used to worship non-human
      love.
      <br />
      <br />
    </p>

    {/* ---------------------------------------------------------- */}
    <h3>Extinction Day</h3>

    <p>
      Characters of the Council:
      <br />
      <br />
      -Precipice, the mechanical mushroom
      <br />
      -Morpher III, the Möbius strip
      <br />
      <br />
      <i>
        In the territory of the illicit, everyday a new Society is built. In the
        ephemeral organization of this cosmos, death and birth were of equal
        value, war and peace of equal gravity. Any time, was the moment for
        novelty to be.
      </i>
      <br />
      <br />
      Precipice:
      <br />
      I wanted to ask you a question since we like philosophical things and
      writing. If there is a god and you were it what would your world look
      like? Would beings be able to talk to you? Is there fate in your universe
      or is it all random? I’d like to know the sort of world you would build,
      because I think it would be better than the one we are in, if you have
      time, I would love to know.
      <br />
      <br />
      Morpher III:
      <br />
      In my world, I would never be god. Or at least that god that is only one
      and that is above us all. In the world where I grow my inner plants, god
      is amongst us, in the negative spaces of the bodies, it inhabits the empty
      places, it decorates the air. In my world, horizontality is not just a
      direction and definitely not flat, it’s plural, it's chaotic too. Humans,
      animals, plants and minerals, all have equal value, none at all or
      absolutely vital. Beginnings are endings and endings are beginnings. Time
      is not a number but a series of tales. Fate is a constant variable, under
      perpetual construction. It refreshes itself like a webpage, if you will.
      It endlessly sources itself in different hosts, just like when you stream
      online videos or download from torrents. It is all so random but also so
      up to all of us that the responsibility of caring for our surroundings
      would be completely for and up to us, amongst us, in between our hands and
      our hormonal exchanges. Pheromones would be the police, instincts our
      law-makers. The world would be one big nation, one big spheral country in
      the world of our galaxy. We would be part of something bigger and our
      nationality would just be Terrestrial.
      <br />
      <br />
      <i>
        Volcanos started to exist inside of seas. The Four elements were no
        longer antagonist to each other.
      </i>
      {/* we use the <br/> for linebreaks in texts*/}
      <br />
    </p>
  </React.Fragment>
);
