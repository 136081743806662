import { Theme } from "app/theme";
import React, { ReactNode } from "react";

interface PageTextProps {
  children: ReactNode;
  align?: string;
}

export const PageText = (props: PageTextProps) => (
  <p
    css={`
      && {
        color: ${Theme.textStyles.detailText.color};
        font-size: ${Theme.textStyles.detailText.fontSize}!important;
        font-family: ${Theme.textStyles.detailText.fontFamily};
        font-weight: ${Theme.textStyles.detailText.fontWeight};
        text-align: ${props.align};
        margin-bottom: 50px !important;

        @media (max-width: 960px) {
          font-size: 16px !important;
        }
      }
    `}
  >
    {props.children}
  </p>
);
