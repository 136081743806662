import React from "react";

export const StoryTheSorceresses = () => (
  <React.Fragment>
    {/* ---------------------------------------------------------- */}
    {/*<h3>The sorceresses</h3>*/}
    {/* we use the <p> tag for paragraphs */}
    <p>
      &emsp;When they gathered around the millenary oak tree, the one that had
      seen more changes in humanity than any other ancient being, the three
      sisters stared at each other in silence. The one closest to the ground,
      started to cry, steadily, with thick tears. The tears soon became a river,
      a river where the two other siblings started to swim in. The tree softly
      moved its branches towards the floating bodies, allowing them to grab an
      extremity and rest on it. As this landscape unfolded, the moon turned
      blue. The clouds, messengers of wanders, softly arrived to frame the
      luminous sphere.
      <br />
      <br />
      &emsp; &emsp;The silence that only belonged to ancient forests and deep
      caves, wrapped the sorrows gathered in the river. This river is made of
      thousands of tears embracing each other, each tear having a universe
      inside, a landscape on its own.
      <br />
      <br />
      Three sisters, a moon and a broken heart.
      </p>
      {/* ---------------------------------------------------------- */}
      <p>
      <i>Odes to past loves in past futures and future loves in lost times</i>
      <br />
      <br />
      Remember the day you looked for mushrooms in your grandma’s garden? you
      kept on mistaking them for the fallen leaves coloured by autumn. That is
      when we noticed you were going blind. I still dream of that day, and all
      the versions of it. I dream of the story many times, in one time.
      Sometimes the colors are different, gravity does not exist or even your
      face is not shaped by your actual features.
      <br />
      I wake up feeling like days passed and I need to tell you all about it.
      But you are not there of course, you already left us many dreams ago.
      <br />
      <br />
      <br />
      She wanted to bump into him so bad. Just to see if her heart would skip,
      and she would feel like an avalanche was happening in her chest, or if she
      would just freeze. Freeze, like she is frozen now. Frozen in time and in
      the shape of the last tear that they shed together when they realized what
      was once infinite became finite, what was once shapeless, crystallised and
      broke into dust.
      <br />
      She wanted to restart her life so, every time she would see the pretzel
      poster in front of the 24hs shop she would not feel like it's laughing at
      her. That picture of the salted piece of bread, looped into a knot,
      intertwined ends, all analogies that seemed to be making fun of what was
      lost.
      <br />
      <br />
      <br />
      I knew from the start that I entered the wrong train. I saw the train,
      knew it was not the one for me, but still decided to choose carefully the
      wagon. As if that would have mattered, as if it would have changed the
      destination at all. I consciously decided to enter this journey knowing it
      was going to fail.
      <br />
      But I thought I could hack life, I could hack my baggage, their baggage,
      their rules and their deadlines. I knew all of it was wrong, wrong for all
      parties. Yet, since I had so consciously chosen where to be, I made a
      point of making that space mine. Sometimes you just want to blame the sky
      for things not working out, yet, I knew the stars and planets had nothing
      to do with this failure.
      <br />
      I always thought I would write letters and songs, poems and essays to you
      but I never did. As if naming the invisible things would make them
      obsolete, unsustainable, unbearable, unbelievable.
     </p>
      {/* ---------------------------------------------------------- */}
      <p>
      in all these months
      <br />
      days
      <br />
      minutes
      <br />
      endless seconds
      <br />
      you held me tight
      <br />
      you held me right.
      <br />
      I wish we had found a way to re-pot the plants we never had together,
      <br />
      move around our soil and make roots grow in different shapes.
      <br />
      We will perhaps sleep again, next to each other, sinking in with what we
      cannot see, and only feel.
      <br />
      I hope in our new garden,
      <br />
      there will be more laughter,
      <br />
      fewer entanglements, and frowning foreheads.
      <br />
      Dark shadows and fears
      <br />
      will be welcomed but not fostered.
      <br />
      I hope we will hold hands again
      <br />
      but as a way to seal our bond,
      <br />
      that needs no shape
      <br />
      nor title.
      <br />
      I hope together
      <br />
      we can invoke freedom once more and
      <br />
      this time,
      <br />
      truly be it
      <br />
      kiss it
      <br />
      let it be our blanket
      <br />
      to protect us against the fires of conventions
      <br />
      and of this alienation that once united us
      <br />
      and, ultimately ripped us too.
    </p>
  </React.Fragment>
);
