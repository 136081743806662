import React, { ReactNode } from "react";
import { Theme } from "app/theme";

interface PageHeaderProps {
  children: ReactNode;
}
export const PageHeader = (props: PageHeaderProps) => (
  <h2
    css={`
      font-family: ${Theme.textStyles.detailTitle.fontFamily};
      font-size: ${Theme.textStyles.detailTitle.fontSize};
      font-weight: ${Theme.textStyles.detailTitle.fontWeight};
      font-style: ${Theme.textStyles.detailTitle.fontStyle};
      color: ${Theme.textStyles.detailTitle.color};
      text-shadow: ${Theme.textEffects[0].textShadow};
      line-height: ${Theme.textStyles.detailTitle.lineHeight};
      text-align: ${Theme.textStyles.detailTitle.textAlign};
      margin: 0;

      @media (max-width: 960px) {
        font-size: 20px;
      }
    `}
  >
    {props.children}
  </h2>
);
