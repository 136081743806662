import React from "react";

export const StoryCanoes = () => (
  <React.Fragment>
    {/* ---------------------------------------------------------- */}
    {/*<h3>Canoes</h3>*/}
    {/* we use the <p> tag for paragraphs */}
    <p>
      I saw you walking around the clouds the other day, you looked so graceful
      and so light. Like you took all the rain and wore it as a suit, like you
      collected the snow and made it into a hat. You built a tent out of sun
      rays and tamed the storm surge with your angelical cries.The mermaids came
      swimming and the centaurs galloped, the birds swirled and surfed the
      tornados of your thoughts. Suspended, in this forest of sighs, you float
      and you sing at ease. The stars are your home and Earth your companion.
      <br />
      You once bent the horizon and twisted the sea. You started a tsunami for
      the soils to revolve. I will always remember the day that you told me:
      <br />
      <br />
      “Falling in love is like finding yourself”
      <br />
      <br />
      I am somewhere on the other side of the dark, watching over the river of
      uncertainties that keeps us apart. But not to worry, I am building a
      canoe, made of leaves and dust, of living crocodiles and glowing eels. It
      will be ready when the sun meets the sea and when you will see what I
      seek.
      <br />
      Warriors of the light and guardians of the shadows.
      <br />
      We will find each other and raft through the waterfalls ahead of us, until
      we reach the end of the world. That end that the Vikings were looking for
      and that the witches and fairies were guarding for eternity.
      {/* we use the <br/> for linebreaks in texts*/}
      <br />
    </p>
  </React.Fragment>
);
