import React from "react";
import { ItemProps } from "app/data";
import { Grid } from "@material-ui/core";
import { PageHeader } from "app/components/PageHeader";
import { PageContainer } from "app/components/PageContainer";
import { motion } from "framer-motion";

import AudioPlayer from "react-h5-audio-player";
import { Parallax } from "react-scroll-parallax";

const transition = {
  delay: 1,
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const imageVariants = {
  initial: { opacity: 0 },
  exit: { opacity: 0, transition },
  enter: {
    opacity: 1,
    transition,
  },
};

const backgroundTransition = {
  initial: { opacity: 0 },
  exit: { opacity: 0, transition },
  enter: {
    opacity: 1,
    duration: 1,
    ease: [0.43, 0.13, 0.23, 0.96],
  },
};

const titleTransition = {
  initial: { opacity: 0 },
  exit: { opacity: 0, transition },
  enter: {
    delay: 1,
    opacity: 1,
    duration: 0.5,
    ease: [0.43, 0.13, 0.23, 0.96],
  },
};

const textTransition = {
  initial: { opacity: 0 },
  exit: { opacity: 0, transition },
  enter: {
    delay: 0.2,
    opacity: 1,
    duration: 0.5,
    ease: [0.43, 0.13, 0.23, 0.96],
  },
};

export const DetailModule = (props: ItemProps) => {
  return (
    <React.Fragment>
      <motion.div initial="exit" animate="enter" exit="exit">
        <PageContainer>
          <Grid
            item
            xs={12}
            lg={12}
            css={`
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <motion.div
              initial="initial"
              animate="enter"
              exit="exit"
              variants={titleTransition}
            >
              <PageHeader>{props.itemName}</PageHeader>
            </motion.div>
          </Grid>
          {props.audioPath && (
            <motion.div
              initial="initial"
              animate="enter"
              exit="exit"
              variants={textTransition}
            >
              <div
                css={`
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  width: 300px;
                  position: fixed;
                  top: 10px;
                  left: 0;
                  z-index: 2000;

                  @media (max-width: 960px) {
                    top: 50px;
                  }
                `}
              >
                <AudioPlayer
                  autoPlay
                  src={props.audioPath}
                  onPlay={(e) => console.log("onPlay")}
                  customVolumeControls={[]}
                  customAdditionalControls={[]}
                  showJumpControls={false}
                />
              </div>
            </motion.div>
          )}

          <Grid item xs={12} sm={7} md={7} lg={7}>
            <motion.div
              initial="initial"
              animate="enter"
              exit="exit"
              variants={imageVariants}
            >
              <div>{props.text}</div>
            </motion.div>
          </Grid>
        </PageContainer>
      </motion.div>

      <Parallax
        y={[`${400}px`, `-${400}px`]}
        css={`
          position: absolute;
          width: 100%;
          z-index: 100;
          top: 0;
          left: 0;
          opacity: 0.5;
          user-select: none;

          /* @media (max-width: 960px) {
            width: 150%;
            transform: translateX(-10%);
          } */
        `}
      >
        <motion.div
          initial="initial"
          animate="enter"
          exit="exit"
          variants={backgroundTransition}
        >
          {props.drawing}
        </motion.div>
      </Parallax>
    </React.Fragment>
  );
};
