import React from "react";

export const StoryLava = () => (
  <React.Fragment>
    {/* ---------------------------------------------------------- */}
    {/*<h3>LAVA</h3>*/}
    {/* we use the <p> tag for paragraphs */}
    <p css={`
        text-align: center;
      `}>
      When Vastness met her soulmate, she didn’t know where to scream. She was
      so used to being the nest of universe’s sorrows that when Meteor came
      crashing into her existence, she was utterly amazed. Their encounter was
      so immediately profound that their togetherness was never even a question.<br />
      What was at stake was how to marry their natures.<br />
      How could granite make love to infinity without dissolving into dust? How could the muffled cries
      that were always lullabies to Vastness not get absorbed by the layers of
      stone? Chemistry and dramaturgy were colliding, this bond did not fit our
      geological age.<br />
      Introspection was the only way left, all the answers were
      inside them both, in their inner planets. Nuclear solutions and galactic
      attempts of togetherness rocked the rhythm of this tumultuous combination
      of pigments.
      <br />
      <br />
      As they delved into the depths of inner wisdom, they gave birth to Quarry.
      {/* we use the <br/> for linebreaks in texts*/}
      <br />
    </p>
  </React.Fragment>
);
