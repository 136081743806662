import React from "react";

export const StoryAbstract = () => (
  <React.Fragment>
    {/* ---------------------------------------------------------- */}
    {/*<h3>Abstract</h3>*/}
    {/* we use the <p> tag for paragraphs */}
    <p>
      &emsp;A group of physicists researching on string theory and other
      possible unified theories were trying to determine how many dimensions the
      universe has, with the hope of finding alternative ways to preserve our
      dying planet. Ecologists, on their side, were attempting to give
      sovereignty back to nature as a radical solution to the water shortage
      announced for 2050. Both parties decided to unite forces to subject
      humanity to a massive structural revolution.
      <br />
      &emsp;The process of intervening both at the cosmological and planetary
      level triggered a second big bang, this time involving dimensions. All
      dimensions were subjected to interactions that led to the merging of some,
      the splitting of others and the disappearance of a few of them. The result
      was a highly non-linear mix of known time and space with other previously
      unknown dimensions that led to a host of unexpected phenomena. Time became
      fractioned, and with it the memory of historical events became completely
      scattered. Matter oscillated into energy and pulsated as a living
      organism; objects dissolved and reappeared as if inhabited by feelings and
      emotions. The notion of reality disappeared, being replaced by an oneiric
      blend in which banalities became worlds on their own, minerals acquired
      personality attributes involving intense love stories and deep crises,
      ancient thinkers reappeared flirting with XXIst century concepts and
      social constructs became shuffled, blurring the boundary between objects
      and living beings. While digitalization took over material existence,
      imagination became a concrete country for conscious beings, with passport
      and all.
      <br />
      &emsp;The world ended several times, at different times, in different
      places. More precisely, time and space ended and arose at many
      multidimensional coordinate points. Nevertheless, existential matters
      remained relevant for some reason, providing the only common thread
      throughout this chaotic universe. And, as a response, despite the violent
      and unpredictable fluctuations, love (and the search of) prevailed in all
      forms.
      {/* we use the <br/> for linebreaks in texts*/}
      <br />
    </p>
  </React.Fragment>
);
