import React from "react";

export const StoryAngryFurniture = () => (
  <React.Fragment>
    {/* ---------------------------------------------------------- */}
    {/*<h3>Angry furniture</h3>*/}
    {/* we use the <p> tag for paragraphs */}
    <p>
      &emsp;The world was one big digital jungle. The transition between
      material to digital capitalism had happened quite brutally over the past
      five years, a whole lot of vestiges were still to be found around. Shops
      were no longer a viable structure for selling goods, and most goods were
      anyway not material anymore. Except for food, existence on Earth was
      happening on cyber realms.
      <br />
      &emsp;Inhabitants of this planet spent their days and weeks and months and
      years, indoors. Socialising, exercising, working and partying from their
      own homes. Homes that were nothing else than walls with sensors that would
      activate depending on the person’s needs. Living spaces were standardised,
      since the customisation was happening solely on the cyber plane. Thanks to
      VR glasses, augmented reality helmets, real life filters, retina implants
      and other devices, all lives were possible.
      &emsp;Animals were almost all extinct and only a few samples survived in labs, as a way to study them in
      order to reproduce them technologically. One of the few animals that
      seemed to have survived the revolution, was the butterfly. Because of its
      extremely short life span and relatively efficient reproductive methods,
      it was left unaltered. The medical field discovered that the systems of
      this flying insect were easy to mimic, technologically speaking, and was
      used for developing ephemeral solutions for various technical problems
      encountered in, for instance, coexistence of identities on multiple
      platforms, search engines clashing etc.
      <br />
      &emsp;Aegus was one of the few cyber-uncorrupted beings on the terrestrial
      surface. Back in the days, when material abundance = wealth (when if
      someone had a lot of objects, it just meant the person had some sort of
      mental disorder or extreme social deviance) Aegus specialised in
      subverting meanings. Meanings of usage of objects, of words, or expected
      emotions. This master in sculpting references to will, re-designed
      physical goods with ease and nonchalance. No rule was left unbent.
      Travestying meanings, he was an endless source of new worlds. When the
      revolution arrived, Aegus' profession became simply obsolete. So
      dramatically that without any transition or moment of limbo, the artisan
      became a complete outcast. Tired of having to exist in a non material
      world, disappointed of having been so abruptly deprived of his vital
      craft, Aegus decided to stop time as an ultimate protest. As his last
      action in this world that was no longer home, he exited the few digital
      platforms that maintained a minimum standard of life, refused all updates
      and new softwares that kept the wall sensors functional. Rejecting to be a
      part of the Intraweb and all its children, the outcast completed his total
      exclusion.
      <br />
      &emsp;Since many years already, 99% of the population decided to become
      data instead of dying, but one very small fraction of perhaps melancholic
      souls or traditional minds, really chose to let themselves die. Many
      options were available to make your transition between life to death. One
      of them, was, shockingly, to decide that what once was called nature,
      would operate by itself. It was rare but feasible.
      <br />
      &emsp;Aegus decided to build a cocoon by gathering all of its most
      precious belongings, the collector items, the totems with sentimental
      values, the symbolic plastic beads, broken toys and screens that once
      needed to be operated by humans. Surrounded by this ode to
      late-capitalism, he waited. Waited until prayers were heard by Big Data.
      Telepathy, by that time a very current communication engine, facilitated
      the last mission of this millennial fossil. A scientist who heard of
      Aegus' life strike, came to visit the strange man. She told him that she
      had been trying to implement the Butterfly technology on a human level,
      and that she thought they could join experiments.Faithful to his raw
      instincts and revolutionary heart, Aegus accepted the collaboration. Soon
      enough, all was in place for his bi-winged transformation.
      <br />
      &emsp;Once Aegus would be reborn, he will have only one day left, one day
      to remember kindness and fragility as the anthem to poetic living.

    </p>
    {/* ---------------------------------------------------------- */}
    <h3>Maggot</h3>
    <p css={`
        text-align: center;
      `}>
      The other day I dreamt you were holding me, and that you let your skin
      grow around us.
      <br />
      I was part of your cocoon.
      <br />
      I realised it was not for me to be there but,
      <br />
      I lingered a bit.
      <br />
      I lingered enough to get a glimpse of your soon to be wings.
      <br />
      It was promising, oh boy, so promising.
      <br />
      The wings were shaped like drops,
      <br />
      Drops of mutated ideas and dinosaurs with soft skin.
      <br />
      Do you see it?
      <br />
      We talked that one time about surviving contemporaneity,
      <br />
      The pace of creations,
      <br />
      of screens,
      <br />
      of clicking and tapping through potentials.
      <br />
      Potential love stories between men and clouds,
      <br />
      Flowers and LED lights.
      <br />
      Solar panels and an old tree.
      <br />
      Do you see it?
      <br />
      Sometimes I remember that,
      <br />
      our instincts went to school together,
      <br />
      and that,
      <br />
      our bodies were shaped by the same Mason.
      <br />
      Maybe one day we will unite in another galaxy
      <br />
      We will be wedded,
      <br />
      united so intensely,
      <br />
      so tightly
      <br />
      like a root hibernating.
      <br />
      or a block of wood destined to become a table.
      <br />
      I am in awe,
      <br />
      I sing to rawness
      <br />
      and kindness
      <br />
      as an anthem to poetic living.
      <br />
      Do you see it?
      <br />
      Because I do,
      <br />
      and my heart screams,
      <br />
      my brain squeezes my hands thaw.
     </p>
      {/* ---------------------------------------------------------- */}
    <p>
      Aegus,
      <br />
      <br />
      It is probably a little unfair that I allow myself to write to you what
      will follow. But, you see, in this world where we have the impression that
      everything is always going wrong and that it is only getting worse, the
      beautiful things, the biting feelings and the magical people, should be
      shared, highlighted, cared for. It is a little complicated but also super
      simple, you know this very little moment of vertigo that happens sometimes
      when an elevator goes too fast? It is a bit uncomfortable but you also
      want it to happen. My heart always squeezes a little like that every time
      I see you. You are so beautiful, it hurts. You sweat poetry. Despite
      considering myself a mere amateur in the love realm, there is something in
      me that whispers to my chest that you and I could really love each other a
      lot. I am under your spell.
      <br />
      <br />
      This Letter goes out a bit without intentions, more like a moment of
      sharing, or maybe even a gift (even if I do not know if it will really
      make you happy or just annoy you). I have never invaded the heart of
      someone like this so I hope this clumsy attempt of sincerity won’t ruin
      things. I hope that at best, it will make us even closer friends and not
      far away strangers.
      <br />
      <br />
      I find you really crazy, between a fairy and an alien, a child and a wise
      druid. All you need to survive in this world where few things are right or
      have true meaning.
      <br />
      <br />
      I always think a lot, even too much, but for once it's only my instinct
      that is screaming. I stop for now, I could continue my poetic praise
      endlessly but I spare you from reading more of these words that may be a
      little futile. Do not feel like you should do something of all this, or
      even answer me. We can talk about it one day maybe, or it can just be part
      of our landscape. Who knows, it might inspire you to make one of your
      weird pieces of furniture, those that are angry with their meaning.
      <br />
      <br />
      <br />
      See you soon a wonderful man.
    </p>
  </React.Fragment>
);
