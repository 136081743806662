// @ts-nocheck
import React, { ReactNode } from "react";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Switch,
} from "react-router-dom";

import { Theme } from "app/theme";
import "app/modules/detail-module/common/story.css";
/* modules */
import { LandingModule } from "app/modules/landing-module";
import { IntroModule } from "app/modules/intro-module";
import { DetailModule } from "app/modules/detail-module";
import { itemList } from "app/data";
import { ColophonModule } from "app/modules/colophone-module";
import { TellMeModule } from "app/modules/tell-me-module";
import { AnimatePresence } from "framer-motion";
// import TestDrawing from "app/assets/drawings/200519_sorceresses.svg";

import history from "history/browser";
import AudioPlayer from "react-h5-audio-player";

interface SideButton {
  children: ReactNode;
  to: string;
}
const SideButton = (props: SideButton) => {
  return (
    <NavLink
      to={`${props.to}`}
      css={`
        text-decoration: none;
      `}
    >
      <span
        css={`
          writing-mode: vertical-lr;
          text-orientation: sideways;
          line-height: 1;
          color: ${Theme.textStyles.sideButton.color};
          font-size: ${Theme.textStyles.sideButton.fontSize};
          font-family: ${Theme.textStyles.sideButton.fontFamily};
          text-shadow: ${Theme.textEffects[0]};

          transition: all 0.2s ease-out;

          &:hover {
            text-shadow: ${Theme.textEffects[1]};
          }
        `}
      >
        {props.children}
      </span>
    </NavLink>
  );
};

history.listen((location: any, action: any) => {
  if (action !== "REPLACE") {
    window["strum"]("routeChange", window.location.href);
  }
});

export function Routes() {
  return (
    <Router history={history}>
      <AnimatePresence exitBeforeEnter initial={true}>
        <Switch>
          <Route exact path="/home">
            <LandingModule />
          </Route>

          <Route exact path="/">
            <IntroModule />
          </Route>

          <Route exact path="/colophon">
            <ColophonModule />
          </Route>

          <Route exact path="/tell-me-a-story">
            <TellMeModule />
          </Route>

          {itemList.map((item) => (
            <Route exact path={item.path} key={item.itemName}>
              <>
                <div
                  css={`
                    position: relative;
                  `}
                >
                  <DetailModule {...item} />
                </div>
              </>
            </Route>
          ))}

          <Route path="*">
            <div
              css={`
                position: fixed;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100vw;
                height: 100vh;
                padding: 30px;
                padding-right: 60px;
              `}
            >
              <span
                css={`
                  font-family: ${Theme.textStyles.siteTitle.fontFamily};
                  font-size: ${Theme.textStyles.siteTitle.fontSize};
                  text-align: center;
                  color: ${Theme.textStyles.siteTitle.color};
                `}
              >
                sorry couldn't find what you were looking for,
                <br /> let's go to the{" "}
                <NavLink
                  css={`
                    color: ${Theme.textStyles.siteTitle.color};

                    text-decoration: underline;
                  `}
                  to="/home"
                >
                  overview
                </NavLink>
                ?
              </span>
            </div>
          </Route>
        </Switch>
      </AnimatePresence>

      <div
        css={`
          top: 0;
          right: 0;
          position: fixed;
          height: 100vh;
          width: 30px;
          padding: 30px;
          padding-right: 40px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          z-index: 2002;

          @media (max-width: 960px) {
            padding-right: 20px;
            height: 100%;
          }
        `}
      >
        <SideButton to="/">Preface</SideButton>
        <SideButton to="/tell-me-a-story">Tell me a story</SideButton>
        <SideButton to="/colophon">Colophon</SideButton>
      </div>
    </Router>
  );
}
