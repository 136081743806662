import React from "react";
import CloseButtonImage from "app/assets/images/close_button.png";
import { NavLink } from "react-router-dom";
import { CloseIcon } from "app/assets/images/CloseIcon";

export const CloseButton = () => (
  <NavLink
    to="/home"
    css={`
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease-out;

      &:hover {
        //opacity: 0.3;
        transform: rotate(-90deg);
      }
    `}
  >
    {/*<img
      css={`
        width: 62px;
        height: 60px;
      `}
      src={CloseButtonImage}
      alt="close button"
    />*/}
    <CloseIcon />
  </NavLink>
);
