import React from "react";
import { LandingModule } from "app/modules/landing-module";
import { Container, CssBaseline, Grid } from "@material-ui/core";
import { DrawingFox } from "app/drawings/Fox";
import { DrawingBeneath } from "app/drawings/Beneat";
import { IntroModule } from "app/modules/intro-module";
import { Routes } from "app/Routes";
import { NavLink } from "react-router-dom";
import Fluid from "app/components/Fluid";
import { ParallaxProvider } from "react-scroll-parallax";

function App() {
  return (
    <React.Fragment>
      <CssBaseline />

      {/*<main
        css={`
          overflow: hidden;
        `}
      >
         https://paveldogreat.github.io/WebGL-Fluid-Simulation/
         https://github.com/PavelDoGreat/WebGL-Fluid-Simulation
        <Fluid />
      </main>*/}
      <Container maxWidth="md">
        <ParallaxProvider scrollAxis="vertical">
          <Routes />
        </ParallaxProvider>
      </Container>
    </React.Fragment>
  );
}

export default App;
