import React from "react";

export const StoryThedarklandoftheUnderground = () => (
  <React.Fragment>
    {/* ---------------------------------------------------------- */}
    {/*<h3>The dark land of the Undergrounds</h3>*/}
    {/* we use the <p> tag for paragraphs */}
    <p>
      &emsp;In the nation of darkness, its Inhabitants lived peacefully. Life
      was reduced to a bare minimum of material belongings. Everyone worked as
      much as they wanted because there was no need for productivity. At least
      Western productivity. Meaning was found within the communal space,
      happiness neither a goal, nor a concern. Overall, it was an uneventful
      life, quiet, moderately sociable but very fair. Under this blanket of
      blue, those that felt a little too mellow organised themselves into
      support groups.
     </p>

      {/* ---------------------------------------------------------- */}
      <h3>Support group</h3>
      <p>
      Characters:
      <br />
      -Black Quartz
      <br />
      -Tourmaline
      <br />
      -Onyx
      <br />
      -Lapiz Lazuli
      <br />
      <br />
      <i>
        The Crystals are gathered together in one of the communal caves. The
        latest album of Vakula is playing
      </i>
      <br />
      <br />
      Black Quartz:
      <br />
      Somehow, when you meet again with people that made you feel strongly, part
      of yourself always re-lives the feeling, even if for just a second. At
      least for sensitive fairies like us. I guess we also linger on that,
      especially because we are surrounded by emotionally constipated beings. So
      we feel for us, for them and for the collective cause. It feels like a
      gift but very often like a burden too, no? doesn't it?
      <br />
      <br />
      Tourmaline:
      <br />
      Listen, you and me both are unable to shine but we can very well set
      boundaries.
      <br />
      <br />
      Onyx:
      <br />
      Friends, I believe the key is in staying up to date, refreshed,
      actualised. Releasing the past but not as an omission of the self, just as
      a way to keep living in harmony with the contemporary surroundings.
      <br />
      <br />
      <i>Lapis Lazuli cannot help but observing the conversation.</i>
      {/* we use the <br/> for linebreaks in texts*/}
      <br />
    </p>
  </React.Fragment>
);
