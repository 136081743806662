import React, { ReactNode } from "react";
import { Grid } from "@material-ui/core";
import { Theme } from "app/theme";
import { CloseButton } from "app/components/CloseButton";
import { SiteHeader } from "app/components/SiteHeader";

interface PageContainerProps {
  children: ReactNode;
}

export const PageContainer = (props: PageContainerProps) => {
  window.scrollTo({ top: 0, behavior: "auto" });
  return (
    <>
      <Grid container item xs={12} lg={12} spacing={2} justify={"center"}>
        <Grid
          item
          xs={12}
          lg={12}
          css={`
            && {
              height: 100px;
              position: sticky;
              top: 0;
              background-color: ${Theme.colors.background};
              padding-top: 0;
              background: linear-gradient(
                180deg,
                rgba(255, 236, 255, 1) 0%,
                rgba(255, 236, 255, 1) 70%,
                rgba(255, 236, 255, 0) 100%
              );
            }
          `}
        >
          <div
            css={`
              height: 30px;
            `}
          />
        </Grid>
        <SiteHeader />
        {props.children}

        <div
          css={`
            display: flex;
            width: 100vw;
            height: 200px;
            justify-content: center;
            align-items: flex-end;

            padding-bottom: 35px;
            z-index: 120;
            background: linear-gradient(
              0deg,
              rgba(255, 236, 255, 1) 0%,
              rgba(255, 236, 255, 1) 60%,
              rgba(255, 236, 255, 0) 100%
            );
          `}
        >
          <CloseButton />
        </div>
      </Grid>

      {/*<div
        css={`
          display: flex;
          width: 100vw;
          height: 200px;
          justify-content: center;
          align-items: flex-end;
          position: fixed;
          bottom: 0;
          left: 0;
          padding-bottom: 35px;
          z-index: 120;
          background: linear-gradient(
            0deg,
            rgba(255, 236, 255, 1) 0%,
            rgba(255, 236, 255, 1) 60%,
            rgba(255, 236, 255, 0) 100%
          );
        `}
      >
        <CloseButton />
      </div>*/}
    </>
  );
};
