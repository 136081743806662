import React, { ReactNode } from "react";
import { Theme } from "app/theme";
import { NavLink } from "react-router-dom";

interface PageHeaderProps {
  children?: ReactNode;
}
export const SiteHeader = (props: PageHeaderProps) => (
  <NavLink
    to={"/home"}
    css={`
      text-decoration: none;
    `}
  >
    <h1
      css={`
        font-family: ${Theme.textStyles.siteTitle.fontFamily};
        font-size: ${Theme.textStyles.siteTitle.fontSize};
        font-weight: ${Theme.textStyles.siteTitle.fontWeight};
        color: ${Theme.textStyles.siteTitle.color};
        text-shadow: ${Theme.textEffects[0].textShadow};
        text-align: ${Theme.textStyles.siteTitle.textAlign};
        font-style: ${Theme.textStyles.siteTitle.fontStyle};
        line-height: ${Theme.textStyles.siteTitle.lineHeight};
        margin: 0;
        transition: all 0.2s ease-out;

        position: fixed;
        width: 100vw;
        top: 25px;
        left: 0;
        z-index: 2000;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          text-shadow: ${Theme.textEffects[1]};
        }
      `}
    >
      Ad Extremum Terrae
    </h1>
  </NavLink>
);
