import React from "react";
import { Grid } from "@material-ui/core";
import { Theme } from "app/theme";
import { CloseIcon } from "app/assets/images/CloseIcon";
import { PageHeader } from "app/components/PageHeader";
import { PageText } from "app/components/PageText";
import { CloseButton } from "app/components/CloseButton";
import { PageContainer } from "app/components/PageContainer";

import { motion } from "framer-motion";

const transition = {
  delay: 0.2,
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const imageVariants = {
  initial: { opacity: 0 },
  exit: { opacity: 0, transition },
  enter: {
    opacity: 1,
    transition,
  },
};

const titleTransition = {
  initial: { opacity: 0 },
  exit: { opacity: 0, transition },
  enter: {
    delay: 0,
    opacity: 1,
    duration: 0.5,
    ease: [0.43, 0.13, 0.23, 0.96],
  },
};

export const IntroModule = () => {
  return (
    <motion.div initial="exit" animate="enter" exit="exit">
      <PageContainer>
        <Grid
          item
          xs={12}
          lg={12}
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          `}
        >
          <motion.div
            initial="initial"
            animate="enter"
            exit="exit"
            variants={titleTransition}
          >
            <PageHeader>
              Anthology of Dystopias and of Ways of Loving
            </PageHeader>
          </motion.div>
        </Grid>

        <Grid item container lg={7}>
          <motion.div
            initial="initial"
            animate="enter"
            exit="exit"
            variants={imageVariants}
          >
            <PageText>
              Through this collection of texts, narratives are seen as
              opportunities rather than destinies; an invitation to multiple
              futures rather than to a fixed fate.It is a concession to
              continue, change and speculate around resolved or unresolved
              threads. By walking through poems that are meant to be songs,
              sliding on misplaced words and getting lost in incoherent paths,
              otherness is seen as a liquid territory. This anthological attempt
              naively rethinks our shapes, identities, ways of loving and
              clumsily tries to exceed human-centered logics. We hope it is
              inviting enough to meander through a mutating space that could be
              as small as a village and as infinite as the Internet network.{" "}
              <br /> <br />
              As a way to dialogue with todays' urgent climate, this project
              became an e-publication instead of an object. Hopefully it serves
              as a starting point for exchanging with other minds as much as,
              hopefully, a momentary escape, a license to speculate on other
              ways of existing in order to relief our uncertain present; a
              written landscape that offers caves to take refuge in, ponds of
              fresh water to bathe in and clouds to get lost with. <br />
              Lou Buche gifted us with his visual world and Antonio Lopez (pia
              io luuuz) with her sonic universe. Virginie Gauthier designed it
              all into a world and Jim Fung gave it life on internet.
              <br /> <br />
              Happy travels!
              <br />
              <br />
              TC Kid
            </PageText>
          </motion.div>
        </Grid>
      </PageContainer>
    </motion.div>
  );
};
