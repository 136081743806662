import React from "react";

export const StoryNoah = () => (
  <React.Fragment>
    {/* ---------------------------------------------------------- */}
    {/*<h3>Noah</h3>*/}
    {/* we use the <p> tag for paragraphs */}
    <p>
      &emsp;They would meet in Noah’s dreams and live all kinds of adventures.
      From the most mundane experiences to the most eccentric ones. Going to the
      market to buy vegetables in alphabetical order, flying hand in hand in a
      zero gravity attraction park, going to see 2D movies… A whole life was
      lived in each dream. That is how, Noah began his love story with Gold, his
      mannequin that had the shape of a pregnant woman, an almost common subject
      to love in the world of total digitalisation. Years passed in seconds and
      all stages of life were lived. Sometimes life would end shortly because
      Gold and Noah went on an excursion to the Himalayas and never came back,
      and sometimes the couple would live a long, very long life in a cottage in
      the French countryside. In their dreams they were always together and
      mutually in love, that was the invariant factor.<br /> &emsp;Noah was anxious to go to sleep every night but his dreams also fed his awake life. His
      multi-existential routine gave him freedom to reinvent himself. So,
      finally, he took back his mic and started to sing. Noah wanted to be a
      rockstar and had everything to be one. Despite his heart asking for a life
      on stage, he spent most of his time animating mannequins through videos
      and helping artisans make their projects come to life. Walking around in
      his clogs and his oversized jeans, he spread light wherever he passed by.
      But Gold had changed everything, and now he sang to her, to her inanimate
      body, to their adventures. He spent his day time imagining what he would
      like to do in this other realm of which he had the keys. Indeed, all was
      possible in the plane of shut eyes. After exploring countless identities,
      he was finally ready to fulfil his biggest desire: to get on stage.
      {/* we use the <br/> for linebreaks in texts*/}
      <br />
    </p>
  </React.Fragment>
);
