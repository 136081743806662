// @ts-nocheck

let colors = {
  background: "#FFECFF",
  foreground: "#ED0D6B",
  shadow: "rgba(237,13,107,0.75)",
  shadowOver: "rgba(255,255,255,0.75)",
};

const fonFamily = {
  oldStandard: "Old Standard TT",
  arial: "Arial Rounded MT Bold",
};
export let Theme = {
  colors: colors,
  fillStyles: [
    {
      name: "landing",
      color: colors.background,
    },
    {
      name: "detail",
      color: colors.foreground,
    },
  ],
  textStyles: {
    body: {
      name: "body",
      fontFamily: fonFamily.arial,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      color: colors.foreground,
      fontWeight: "normal",
    },
    landingItemLink: {
      name: "landing-item-link",
      fontFamily: fonFamily.oldStandard,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      color: colors.foreground,
      fontWeight: "normal",
    },
    landingItemName: {
      name: "landing-item-name",
      fontFamily: fonFamily.oldStandard,
      fontStyle: "italic",
      fontWeight: "normal",
      fontSize: "24px",
      color: colors.foreground,
    },
    siteTitle: {
      name: "detail-title",
      fontFamily: fonFamily.oldStandard,
      fontStyle: "italic",
      fontWeight: "normal",
      fontSize: "30px",
      lineHeight: 1,
      textAlign: "center",
      color: colors.foreground,
    },
    detailTitle: {
      name: "detail-title",
      fontFamily: fonFamily.oldStandard,
      fontStyle: "italic",
      fontWeight: "normal",
      fontSize: "30px",
      lineHeight: 1,
      textAlign: "center",
      color: colors.foreground,
    },
    detailText: {
      name: "detail-text",
      fontFamily: fonFamily.oldStandard,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "20px",
      color: colors.foreground,
    },
    sideButton: {
      name: "side-button",
      fontFamily: "Arial Rounded MT Bold",
      fontSize: "14px",
      color: colors.foreground,
    },
  },
  textEffects: [
    {
      name: "shadow",
      textShadow: `5px 3px 12px ${colors.shadow}`,
    },
    {
      name: "shadow-mouse-over",
      textShadow: `5px 3px 12px ${colors.shadowOver}`,
    },
  ],
};
