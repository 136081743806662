import React from "react";
import { Grid } from "@material-ui/core";
import { PageHeader } from "app/components/PageHeader";
import { PageText } from "app/components/PageText";
import { CloseIcon } from "app/assets/images/CloseIcon";
import { CloseButton } from "app/components/CloseButton";
import { PageContainer } from "app/components/PageContainer";
import { motion } from "framer-motion";

const transition = {
  delay: 0.2,
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const imageVariants = {
  initial: { opacity: 0 },
  exit: { opacity: 0, transition },
  enter: {
    opacity: 1,
    transition,
  },
};

const titleTransition = {
  initial: { opacity: 0 },
  exit: { opacity: 0, transition },
  enter: {
    delay: 0,
    opacity: 1,
    duration: 0.5,
    ease: [0.43, 0.13, 0.23, 0.96],
  },
};

export const ColophonModule = () => {
  return (
    <motion.div initial="exit" animate="enter" exit="exit">
      <PageContainer>
        <Grid item xs={12} lg={12}>
          <motion.div
            initial="initial"
            animate="enter"
            exit="exit"
            variants={titleTransition}
          >
            <PageHeader>
              Anthology of Dystopias and of Ways of Loving
            </PageHeader>
          </motion.div>
        </Grid>

        <Grid item xs={12} lg={8}>
          <motion.div
            initial="initial"
            animate="enter"
            exit="exit"
            variants={imageVariants}
          >
            <PageText align={"center"}>
              Concept and Writing: TC Kid
              <br />
              Audiowork: Antonio López Espinosa (pía ío luuuz)
              <br />
              Illustrations: Lou Buche
              <br />
              Design: Virginie Gauthier
              <br />
              Development: Jim Fung
            </PageText>
          </motion.div>
        </Grid>
      </PageContainer>
    </motion.div>
  );
};
