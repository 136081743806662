import React from "react";
import { Grid } from "@material-ui/core";
import { PageHeader } from "app/components/PageHeader";
import { PageText } from "app/components/PageText";
import { CloseButton } from "app/components/CloseButton";
import { Theme } from "app/theme";
import { PageContainer } from "app/components/PageContainer";
import { StaticKitProvider } from "@statickit/react";

import { useForm } from "@statickit/react";

import { motion } from "framer-motion";

const transition = {
  delay: 0.2,
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const imageVariants = {
  initial: { opacity: 0 },
  exit: { opacity: 0, transition },
  enter: {
    opacity: 1,
    transition,
  },
};

const titleTransition = {
  initial: { opacity: 0 },
  exit: { opacity: 0, transition },
  enter: {
    delay: 0,
    opacity: 1,
    duration: 0.5,
    ease: [0.43, 0.13, 0.23, 0.96],
  },
};

function MyForm() {
  const [state, handleSubmit] = useForm("contact");
  if (state.succeeded) {
    return <div>Thank you for sending me your story!</div>;
  }
  return (
    <form
      onSubmit={handleSubmit}
      css={`
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <textarea
        css={`
          height: 100px;
          width: 100%;
          margin-bottom: 20px;
          background-color: transparent;
          padding: 20px;
          resize: none;
        `}
        id="story"
        name="story"
      />
      <button type="submit" disabled={state.submitting}>
        Write me
      </button>
    </form>
  );
}

export const TellMeModule = () => {
  return (
    <motion.div initial="exit" animate="enter" exit="exit">
      <StaticKitProvider site="d4a5668dbfa2">
        <PageContainer>
          <Grid item xs={11} lg={8}>
            <motion.div
              initial="initial"
              animate="enter"
              exit="exit"
              variants={imageVariants}
            >
              <PageText>
                Despite never intending to produce digitally, I embrace our
                contemporary context and use this format as a witness of current
                times. COVID19 added a layer to the tales, activating them in
                unpredictable ways. By collecting reactions/collaborations, I
                hope to create an archive that gives importance to the fiction
                as much as to the real. So, If you want to share with me where
                you got lost or want to show me a secret passage you found, a
                song that the birds sang to you or perhaps you want to become
                Queen of one of the worlds ...
              </PageText>
            </motion.div>
          </Grid>
          <Grid container item lg={12} justify={"center"}>
            <MyForm />
          </Grid>
        </PageContainer>
      </StaticKitProvider>
    </motion.div>
  );
};
