import React from "react";

export const StoryCharlieFinn = () => (
  <React.Fragment>
    {/* ---------------------------------------------------------- */}
    {/*<h3>Charlie Finn</h3>*/}
    {/* we use the <p> tag for paragraphs */}
    <p>
      &emsp;When Charlie came to earth, they already knew a whole lot. Something
      in their eyes carried a wisdom from a thousand lives and a million
      adventures. They were born secretly, no one knew exactly how, Charlie
      appeared one day, dreaming on the tentacles of an octopus. The creatures
      crawled around Crosby Beach until a couple of alchemists invited them to
      join their family. It is like they always knew each other. <br />
      &emsp;The octopus, having completed what seemed to be its mission (of
      bringing Charlie somewhere caring), stayed three days and disappeared,
      leaving behind one tentacle. The tentacle had its place on a shelf, next
      to the encyclopaedias and the cooking books. <br />
      &emsp;Charlie had the shape of a human but only when they wanted to. In
      fact, they had this incredible ability to mould their body, change the
      shape and the place of their limbs, use their eyes where they wanted etc.
      Luckily, Charlie’s adoptive parents were used to accepting what we cannot
      see nor explain so, the nature of this being was never an issue nor a
      source of worry. The community to which they belonged was one of druids
      and snake charmers. Witches from all over the world came to seek shelter
      in this village, where names, origins and purposes were not a topic of
      conversation. <br />
      &emsp;Humans talked way too much so Charlie decided to keep one ear for
      themselves. They kept their left ear, the one that was more sensitive to
      colours and feelings, music and spells, inside of their chest, right under
      the heart, resting in the rib basket. In its cartilaginous kingdom, the
      organ of hearing became best friends with the pumping muscle right above.
      Listening to each others' lullabies, they told each other stories and
      myths they once heard when they belonged to other systems.
      {/* we use the <br/> for linebreaks in texts*/}
      <br />
    </p>
  </React.Fragment>
);
