import * as React from "react";

export const CloseIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg width={66} height={64} viewBox="0 0 66 64" fill="none" {...props}>
      <path
        stroke="#ED0D6B"
        strokeOpacity={0.75}
        strokeWidth={1.8}
        d="M2.636 1.364l62 62m-63.272 0l62-62"
      />
    </svg>
  );
};
