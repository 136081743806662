// @ts-nocheck
import React, { ReactChild } from "react";
import { ItemProps } from "app/data";
import { ItemName } from "./LinkItem";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

const thumbnailVariants = {
  initial: { opacity: 0 },
  enter: { opacity: 1, transition },
  exit: {
    opacity: 0,
    transition: { duration: 1.5, ...transition },
  },
};

/* ------------------------------------------------------------- */

export const LandingItem = (props: ItemProps) => {
  return (
    <motion.div className="thumbnail" variants={thumbnailVariants}>
      <NavLink
        to={`${props.path}`}
        exact
        css={`
          display: flex;
          margin-bottom: 20px;
          align-items: flex-end;
          text-decoration: none;
        `}
      >
        <ItemName>{props.itemName}</ItemName>
      </NavLink>
    </motion.div>
  );
};
