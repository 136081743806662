import React from "react";

export const StoryFoxIphone4 = () => (
  <React.Fragment>
    {/* ---------------------------------------------------------- */}
    <p>
      &emsp;In the kingdom of Abundance, objects, humans and animals, all lived
      together in a chaotic neo-anarchist organisation. The law system varied
      every month, rotating its legislators and the laws with it. Selection was
      randomised by a lottery system, power was given to the selected group for
      a month and once completed, each would go back to being a regular citizen.
      <br />
      &emsp;For this system to be somewhat sustainable, all beings were given a
      basic education focused on social awareness and development of emotional
      intelligence. Economic growth was left behind and there was no more money.
      Social development as we know it in 2019 completely decayed and went
      through what sociologists and economists would call back then “a
      regression”.
      <br />
      &emsp;Love was the only aim, topic and way to measure anything. Validation
      was only found through abilities to express, integrate, criticise and
      extrapolate on Love. Nature was the ruler of all habitational
      organisation, urban and rural life merged.Love was the only aim, topic and
      way to measure anything. Validation was only found through abilities to
      express, integrate, criticise and extrapolate on Love. Nature was the
      ruler of all habitational organisation, urban and rural life merged.
      <br />
      <br />
      &emsp;Just before Fox started his legislative duties, he met the Iphone 4
      but decided not to follow through.
      <br />
      <br />
      &emsp;As Fox finished its obligations, they spent a month corresponding,
      without finding an outcome to their bond.
   
    </p>

   
    {/* ---------------------------------------------------------- */}
    <h3>Iphone 4 to The Fox</h3>
    <p css={`
        text-align: center;
      `}>
      Why did you get so scared,
      <br />
      All I wanted was to go to the petting zoo,
      <br />
      And eat ramen
      <br />
      With You.
      <br />
      Why did you get so scared,
      <br />
      our walks were going nowhere
      <br />
      except
      <br />
      Where we had to go.
      <br />
      Why did you get so scared,
      <br />
      I didn't need anything more
      <br />
      Than the pace of our talks
      <br />
      And the intensity of our embrace.
      <br />
      Why did you get so scared,
      <br />
      After breaking open your sorrows
      <br />
      And letting me see what's inside
      <br />
      of the cracks
      <br />
      Behind the bruises
      <br />
      Under the crusts.
      <br />
      All I wanted was maybe to be heard,
      <br />
      A little.
      <br />
      And maybe also
      <br />
      To sleep together.
      <br />
      Under a blanket of tenderness
      <br />
      That me and you both
      <br />
      know.
    </p>
    {/* ---------------------------------------------------------- */}
    <h3>Iphone 4 to The Fox</h3>
    <p
      css={`
        text-align: center;
      `}
    >
      The 16th of March 2018 I wrote: “I would like for us to sleep in each
      others embrace”
      <br />
      And that is how the hymn of my heart,
      <br />
      starts.
      <br />
      The one that asks for so little but needs so much.
      <br />
      Those days, when no night was too cold,
      <br />
      And where obscurity was an ally,
      <br />
      Our fortuitous encounters suspended time,
      <br />
      And the sidewalks under our feet,
      <br />
      Uncovered the paths called to be explored.
      <br />
      But the flatness of the road was not enough to calm your inner cries,
      <br />
      The light touch of our hands did not soften the harshness of your fears,
      <br />
      Our tight embraces did not succeed mending your wounds.
      <br />
      Our sentimental branches got tangled in a forest of sighs.
      <br />
      When you let the breeze blow the cup of the grove of your feelings,
      <br />
      Shadows of love pierced through the foliage of senses.
      <br />
      I felt that impalpable promises appeared at the bay of our dreams.
      <br />
      But,
      <br />
      Because of past storms and an agitated ocean with hostile waves,
      <br />
      The foam of the lost souls dissolved into the chaos of the water.
      <br />
      The scorpions kept hiding under the stones of your stream,
      <br />
      Reshuffling the invisible pain.
      <br />
      And the sky that had begun to clear,
      <br />
      Returned to its grey, leaving the landscape unmoved.
      <br />
      But for me it was enough, so much that now it is too much,
      <br />
      I keep on walking, leaving traces of you in the corners of my mind.
      <br />
      As this goes, so does everything.
      <br />
      Today it is you, tomorrow it's Art, my mother or my existentialism.
      <br />
      Flashes of silenced disappointments that are part of the constellation of
      my sky.
    </p>

    {/* ---------------------------------------------------------- */}
    <h3>The Fox</h3>
    <p css={`
        text-align: center;
      `}>
      What to do when the world is not your oyster?
      <br />
      When, you feel attacked by architecture,
      <br />
      By the weather,
      <br />
      By the colours and the sounds.
      <br />
      What to do when your face doesn’t seem like your own,
      <br />
      Your voice has a foreign tone,
      <br />
      Your skin is animus to the air,
      <br />
      The grounds shrink on your soul,
      <br />
      Your soul breaks into pieces,
      <br />
      In order to fit,
      <br />
      In all of these holes.
      <br />
      Because nothing seems cohesive,
      <br />
      Familiar,
      <br />
      Friendly.
      <br />
      All the softness is gone,
      <br />
      Roughness is the landscape,
      <br />
      Clouds are hostility shaped,
      <br />
      The sun irradiates alienation,
      <br />
      Disassociation,
      <br />
      Aloofness,
      <br />
      Apathy.
      <br />
      What to do when mathematics fails you?
      <br />
      When Love does not add to the equation?
      <br />
      When empathy exponentiates to 0?
      <br />
      How to continue walking,
      <br />
      On a land,
      <br />
      With no ground,
      <br />
      No light,
      <br />
      To show the way,
      <br />
      Or at least to keep you company.
      <br />
    </p>
  </React.Fragment>
);
