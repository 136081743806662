import React from "react";

export const StoryBeneathTheDark = () => (
  <React.Fragment>
    {/* ---------------------------------------------------------- */}
    {/*<h3>Beneath the dark</h3>*/}
    {/* we use the <p> tag for paragraphs */}
    <p>
      &emsp;There was a basement where a summary of society would gather. Every
      time the sun would take rest in the horizon, the walls of this underground
      concrete box trembled to beats and melodies. Bodies would restlessly
      compose a moving hymn for this Nation of the Night.
      <br />
      &emsp;This micro cosmos of hedonism was guarded by a Sphinx. This creature
      had as sole mission to safeguard the gates of the nocturnal universe.
      Pheromones and lost thoughts danced together. Together until the Moon was
      done ruling and folded herself back into the blanket of the Day. Quietly,
      in some of the corners of this square, hustling for some oxygen, a troupe
      of plants, witnessed it all. Big and fibrous they took space but moulded
      themselves to the environment with subtlety.
      <br />
      &emsp;Nourishing themselves only through incandescent lights, getting just
      the necessary colours for survival. There were no indulgences nor
      abundance in the life of these silent spectators. In total contrast with
      the Human Happening, where affluence of volume, movement, visual and
      sensorial stimulations was the motto of this space, the shrubs had a
      hermit life.
      <br />
      &emsp;Thriving mostly on the poetry that unfolded in their nightly empire,
      they meditated on the mantras of physical ecstasy.
      <br />
      <br />
      &emsp;Murmuring amongst themselves “Capitalism is keeping humanity alive”.
      {/* we use the <br/> for linebreaks in texts*/}
      <br />
    </p>
  </React.Fragment>
);
