// @ts-nocheck
import React, { useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import { LandingItem } from "app/modules/landing-module/common/landing-item";
import { DrawingBeneath } from "app/drawings/Beneat";
import { DrawingFox } from "app/drawings/Fox";
import { itemList } from "app/data";

// import Fluid from "app/components/Fluid";
import { SiteHeader } from "app/components/SiteHeader";
import { DrawingCanoes } from "app/drawings/Canoes";

import { motion } from "framer-motion";

const transitionInForeground = {
  delay: 0.1,
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};
const transitionOutForeground = {
  duration: 1.5,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const transitionInBackground = {
  duration: 0.5,
  ease: [0.43, 0.13, 0.23, 0.96],
};
const transitionOutBackround = {
  duration: 1.5,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const thumbnailVariants = {
  initial: { opacity: 0 },
  enter: { opacity: 1, transition: { ...transitionInForeground } },
  exit: {
    opacity: 0,
    transition: { ...transitionOutForeground },
  },
};

const backgroundTransition = {
  initial: { opacity: 0 },
  enter: { opacity: 1, transition: transitionInBackground },
  exit: {
    opacity: 0,
    transition: transitionOutBackround,
  },
};

export const LandingModule = () => {
  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid
          item
          lg={6}
          css={`
            position: fixed;
            left: 30px;
            top: 30px;
            z-index: 2001;
            /* outline: solid 1px green; */

            @media (max-width: 960px) {
              top: 80px;
              left: 15px;
            }
          `}
        >
          <motion.div
            initial="initial"
            animate="enter"
            exit="exit"
            variants={thumbnailVariants}
          >
            {itemList.map((item) => (
              <LandingItem key={item.itemName} {...item} />
            ))}
          </motion.div>
        </Grid>

        <SiteHeader />
      </Grid>

      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={backgroundTransition}
      >
        <DrawingFox
          css={`
            transform: translateX(-130px);

            @media (max-width: 960px) {
              width: 100%;
              transform: translate(-30px, 5px);
            }
          `}
        />
      </motion.div>
    </React.Fragment>
  );
};
